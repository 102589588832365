var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"table-box mt-5",attrs:{"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.HOME_QUOTES_TABLE}},[_c('Loading',{attrs:{"loading":_vm.loading}}),_c('GlobalEvents',{on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==83){ return null; }if(!$event.altKey){ return null; }return _vm.keymap.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==219){ return null; }if(!$event.shiftKey){ return null; }return _vm.keymap.apply(null, arguments)}]}}),_c('div',{staticClass:"columns is-flex-mobile"},[_vm._m(0),_c('div',{staticClass:"column has-text-right"},[_c('NewQuoteButton')],1)]),(_vm.loading)?_c('QuotesTableSkeleton',{attrs:{"rows":10}}):_c('b-table',{attrs:{"data":_vm.itinerariesLimited,"row-class":_vm.getItineraryRowClass,"hoverable":""}},[_c('b-table-column',{attrs:{"field":"client","label":"Client","width":"15%"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.getFieldValidatedData(props.row, "client", "name"))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"type","label":"Type","width":"10%","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.helper.getItineraryTypeById(props.row.itinerary_type_id))?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(" "+_vm._s(_vm.helper.getItineraryTypeById(props.row.itinerary_type_id).name)+" ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"code","label":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-navbar-item',{staticClass:"color-secondary cursor quote__link",staticStyle:{"cursor":"pointer"},attrs:{"tag":"router-link","to":{
          path: _vm.constants.PATH_INFO.BASIC_INFO.path,
          query: { itinerary: props.row.id },
        }}},[_vm._v(" "+_vm._s(props.row.code)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"user","label":"User"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.user.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"title","label":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-navbar-item',{staticClass:"color-secondary cursor quote__link",staticStyle:{"cursor":"pointer"},attrs:{"tag":"router-link","to":{
          path: _vm.constants.PATH_INFO.BASIC_INFO.path,
          query: { itinerary: props.row.id },
        }}},[_vm._v(_vm._s(props.row.title))])]}}])}),_c('b-table-column',{attrs:{"field":"paxs","label":"Paxs","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.num_pax)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"price","label":"Price","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.price)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"from","label":"Dates","centered":"","width":"8%"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("customDate")(props.row.start_date,_vm.constants.DATE_FORMAT_MMM_DD_YYYY))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("customDate")(props.row.end_date,_vm.constants.DATE_FORMAT_MMM_DD_YYYY))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"status","label":"Status","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('BaseSelect',{attrs:{"size":"xs","list":_vm.status_itinerary,"optionValue":"id","optionText":"name","placeholder":"Status","lock":props.row.status.id !== 1,"disabled":props.row.status.id !== 1},on:{"input":function($event){_vm.openModal = true}},model:{value:(props.row.status.id),callback:function ($$v) {_vm.$set(props.row.status, "id", $$v)},expression:"props.row.status.id"}}),_c('BaseModal',{attrs:{"isComponentModalActive":_vm.openModal}},[_c('IconWarning',{staticClass:"icon is-xsmall color-12",attrs:{"slot":"icon"},slot:"icon"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Are you sure to "),_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v("change")]),_vm._v(" this status? ")]),_c('span',{attrs:{"slot":"paragraph"},slot:"paragraph"},[_vm._v("The itinerary cannot be modified in the")]),_c('div',{staticClass:"is-flex",attrs:{"slot":"buttons"},slot:"buttons"},[_c('BaseButton',{staticClass:"mr-4",attrs:{"action":"secondary","size":"medium","disabled":_vm.loading},on:{"click":function () {
                _vm.loadItineraries();
                _vm.openModal = false;
              }}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("No, take me back")])]),_c('BaseButton',{attrs:{"action":"tercery","size":"medium","disabled":_vm.loading},on:{"click":function($event){return _vm.handleChangeStatus(props.row.status.id, props.row.id)}}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Yes, continue")])])],1)],1)]}}])}),_c('b-table-column',{attrs:{"field":"edit","label":"Actions","width":"6%","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.isExporting)?_c('p',{staticClass:"itinerary_row_tag"},[_vm._v(" Exporting to OpenOrange ")]):_c('div',{staticClass:"is-flex is-justify-content-center is-align-items-center"},[_c('a',{staticClass:"ml-2",on:{"click":function($event){$event.preventDefault();return _vm.handleEditItinerary(props.row)}}},[_c('IconPencil',{staticClass:"icon color-4 is-small"})],1),_c('QuotesTableActions',{attrs:{"data":props.row}})],1)]}}])})],1),_c('div',{staticClass:"shortcutLinkContainer"},[_c('BaseShortcutLink',{staticClass:"shortcutsLink",on:{"click":_vm.handleModal}}),_c('BaseTourGuideLink',{staticClass:"shortcutsLink",attrs:{"handleClick":_vm.openGuideModal}}),_c('BaseModal',{attrs:{"isComponentModalActive":_vm.shortcutsOpen,"type":"shortcuts","shortcuts":_vm.shortcuts,"closeModal":_vm.handleModal}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Recent Itineraries keyboard shortcuts ")])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"is-h2 p-1 pb-3"},[_c('strong',[_vm._v("Recent Itineraries")])])])}]

export { render, staticRenderFns }